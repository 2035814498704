import React, { FC } from 'react';

export interface Props {
    url: string,
    text: string,
    image: string,
}

const PortfolioItem: FC<Props> = ({ url, text, image }) => {
    return (
        <a href={`${url}`} className="relative w-full h-full h-64 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl overflow-hidden group" target="_blank" rel="noopener noreferrer nofollow">
            <img src={image} alt={text} className="absolute top-0 mx-auto transform translate-x-0 translate-y-12 group-hover:translate-y-4 transition ease-in-out duration-200 group-hover:top-0 object-contain rounded -rotate-12 z-10"/>
            <span className="inline-block relative z-30 m-3 text-xs leading-6 font-bold text-white bg-sky-400/20 rounded-full py-1 px-3 group-hover:bg-sky-400/50">{text}<svg className="inline-block mt-1 w-3 h-3 ml-3 overflow-visible text-sky-300 dark:text-sky-400" aria-hidden="true"><path d="M0 0L3 3L0 6" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
        </a>
    )
}

export default PortfolioItem;